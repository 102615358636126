import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { SwitchableThemeProvider } from './lib/themes/switchable-theme-context';
import { GlobalStyle } from './lib/global-style';
import { PayForm } from './features/pay/pages/pay-form';
import { color } from './lib/themes';
import { commonAPI } from './lib/api';
import { Invoice, InvoiceStatus } from './types/app';

const invoiceId = window.location.pathname.substring(1);

function App() {
  const [invoice, setInvoice] = useState<Invoice|null>(null);

  useEffect(() => {
    const handleGetInvoice = (interval?: NodeJS.Timer) => {
      commonAPI
        .getInvoice(null, { invoiceId })
        .then(newInvoice => {
          if ([InvoiceStatus.EXPIRED, InvoiceStatus.PAID, InvoiceStatus.PAID_PARTIALLY, InvoiceStatus.OVERPAID].includes(newInvoice.status) && interval) {
            clearInterval(interval);
          }

          setInvoice(newInvoice);
        });
    };

    handleGetInvoice();

    const interval: NodeJS.Timer = setInterval(() => handleGetInvoice(interval), 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <SwitchableThemeProvider>
      {/* @ts-ignore TODO: solve */}
      <GlobalStyle />

      <Toaster
        position="top-right"
        reverseOrder={false}
      />

      {invoice ? <PayForm setInvoice={setInvoice} invoice={invoice!} /> : (
        <PreloaderContainer>
          <CircularProgress size={40} thickness={4} />
        </PreloaderContainer>
      )}
    </SwitchableThemeProvider>
  );
}

export default App;

const PreloaderContainer = styled.div`
    align-items: center;
    display: flex;
    height: 480px;
    justify-content: center;
    
    svg {
        color: ${color('primary')};
        
        circle {
            stroke-linecap: round;
        }
    }
`;
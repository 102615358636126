import { createContext, FC, PropsWithChildren, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from './light';
import { darkTheme } from './dark';
import { useLocalStorage } from '../hooks';

type TSwitchableThemeContext = ['light' | 'dark', () => void, (newTheme: TSwitchableThemeContext[0]) => void];

export const SwitchableThemeContext = createContext<TSwitchableThemeContext>(['light', () => {}, (newTheme: TSwitchableThemeContext[0]) => {}]);

export const SwitchableThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('app_theme', 'dark');

  const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light');

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <SwitchableThemeContext.Provider value={[theme, toggleTheme, setTheme]}>
        {children}
      </SwitchableThemeContext.Provider>
    </ThemeProvider>
  )
}
import styled from 'styled-components';
import { color } from '../../lib/themes';
import { Button } from '../../ui/button';
import { Search } from '../../ui/search';
import { ItemRow } from './item-row';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  handleBack(): void;
}

interface FAQItem {
  title: string;
  text: string;
}

export const FAQ: FC<Props> = ({ handleBack }) => {
  const [t] = useTranslation()
  const [searchValue, setSearchValue] = useState<string|null>(null);

  return (
    <Container>
      <FAQButton onClick={handleBack} className="secondary medium">
        {t('faq.back')}
      </FAQButton>
      <Content>
        <Search
          placeholder={t('search_placeholder')}
          onChange={setSearchValue}
        />
        <Accordion>
          {(t('faq.items', { returnObjects: true }) as FAQItem[]).map(faqElement => (
            <ItemRow key={faqElement.title} title={faqElement.title}>
              {faqElement.text}
            </ItemRow>
          ))}
        </Accordion>
      </Content>
    </Container>
  )
}

const Container = styled.div`
    background-color: ${color('Dark_bg1')};
    border-radius: 0 0 24px 24px;
    color: ${color('primary')};
    overflow: hidden;
`;

const FAQButton = styled(Button)`
    background: ${p => color(`success-${p.theme.theme}`)(p)} !important;
    border-radius: 0;
    width: 100%;
    
    color: ${p => p.theme.theme === 'dark' ? undefined : color('white')(p)} !important;
    
    &:hover {
        opacity: 0.85 !important;
    }
`;

const Content = styled.div`
    height: 416px;
    overflow-y: auto;
    padding: 32px 32px 0;
`;

const Accordion = styled.div`
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: ${color('primary')};
`;

import axios, { AxiosRequestConfig, Method } from 'axios';
import { fillEndpointTemplate } from './fill-endpoint-template';

type SimpleEndpointRequestConfig = Omit<
  AxiosRequestConfig,
  'url' | 'method' | 'params'
>;

export type SimpleEndpoint<P extends {}, T, R> = {
  (
    params?: T | null,
    routeParams?: R | null,
    config?: SimpleEndpointRequestConfig,
  ): Promise<P>;
};

export type SimpleApi<A extends object> = {
  [M in keyof A]: A[M];
};

const createSimpleApi = (baseURL: string|null = null) => {
  return function createEndpoint<P extends {} = {}, T = any, R = any>(
    method: string,
    httpMethod: Method = 'GET',
  ): SimpleEndpoint<P, T, R> {
    const sendParamsInBody = ['POST', 'PUT', 'PATCH'].includes(httpMethod);

    return (
      params = null,
      routeParams = null,
      config: SimpleEndpointRequestConfig = {},
    ) =>
      axios
        .request<P>({
          withCredentials: true,
          method: httpMethod,
          url: `${baseURL}/${fillEndpointTemplate(method, routeParams as any)}`,
          data: sendParamsInBody ? params : undefined,
          params: sendParamsInBody ? undefined : params,
          ...config,
        })
        .then(response => response.data);
  };
};

export { createSimpleApi };

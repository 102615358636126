import styled from 'styled-components';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { SwitchableThemeContext } from '../../lib/themes/switchable-theme-context';
import { color } from '../../lib/themes';
import { Menu } from '../menu';
import { Invoice, InvoiceStatus } from '../../types/app';
import { ReactComponent as LogoIcon } from './static/logo.svg';
import { ReactComponent as FAQIcon } from './static/FAQ.svg';
import { ReactComponent as SettingsIcon } from './static/settings.svg';
import { ReactComponent as SunIcon } from './static/sun.svg';
import { ReactComponent as MoonIcon } from './static/moon.svg';
import { ReactComponent as ArrowIcon } from './static/arrow.svg';

interface Props {
  handleFaqClick(): void;
  invoice: Invoice;
}

export const Header: FC<Props> = ({ handleFaqClick, invoice }) => {
  const [t] = useTranslation();
  const [theme, toggleTheme] = useContext(SwitchableThemeContext);

  const { branding } = invoice;

  const backUrl = useMemo(() => {
    if ([InvoiceStatus.PAID, InvoiceStatus.OVERPAID].includes(invoice.status)) {
      return invoice.branding.success_url;
    }

    if ([InvoiceStatus.PAID_PARTIALLY, InvoiceStatus.EXPIRED].includes(invoice.status)) {
      return invoice.branding.fail_url;
    }

    return invoice.branding.site_url;
  }, [invoice]);

  return (
    <HeaderContainer>
      <Tooltip title={t('back_to_site')} arrow placement="top-start">
        <BackButton href={backUrl}>
          <ArrowIcon />
        </BackButton>
      </Tooltip>

      {(branding.icon || branding.light_icon) ? (
        <CustomLogo
          src={theme === 'light' ? (branding.light_icon || branding.icon!) : (branding.icon || branding.light_icon!)}
          alt={branding.name}
        />
      ) : (
        <HeaderText>
          <LogoIcon />

          <span>silus</span>
        </HeaderText>
      )}
      <HeaderRight>
        <HeaderIconButton onClick={toggleTheme}>
          {theme === 'light' ? <SunIcon /> : <MoonIcon />}
        </HeaderIconButton>
        <Tooltip title={t('faq_tooltip')} arrow placement="bottom-end">
          <HeaderIconButton onClick={handleFaqClick}>
            <FAQIcon />
          </HeaderIconButton>
        </Tooltip>
        <Menu>
          <HeaderIconButton>
            <SettingsIcon />
          </HeaderIconButton>
        </Menu>
      </HeaderRight>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 0;
    position: relative;
    
    @media (max-width: 768px) {
        padding: 16px 16px 0;
    }
`;

const HeaderText = styled.div`
    align-items: center;
    display: flex;
    font-family: Neopixel, Biennale, Golos, sans-serif;
    font-size: 28px;
    font-weight: 400;
    gap: 8px;
    grid-area: logo;
    letter-spacing: -.59px;
    line-height: 36px;
    
    svg {
        width: 32px;
        height: 32px;
    }
`;

const CustomLogo = styled.img`
    max-width: 128px;
    height: 32px;
`;

const HeaderRight = styled.div`
    column-gap: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
`;

const HeaderIconButton = styled.button`
    align-items: center;
    background: ${color('secondary')};
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    transition: all .3s ease;
    width: 40px;

    svg {
        min-height: 24px;
        min-width: 24px;
        color: ${color('primary')};
    }

    &:hover {
        background: ${color('buttonHeaderHover')};
    }
`;

const BackButton = styled.a`
    align-items: center;
    background: ${color('secondary')};
    border-radius: 40px;
    box-shadow: ${color('sElevation_3')};
    color: ${color('contentPrimary')};
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 44px;
    justify-content: center;
    left: -22px;
    position: absolute;
    transition: all .3s ease;
    width: 44px;
    
    @media (max-width: 768px) {
        display: none;
    }
`;
import styled, { css } from 'styled-components';
import React, { FC, useEffect, useState } from 'react';
import { Header } from '../../../ui/header';
import { PaymentDetails } from '../../../ui/payment-details';
import { Checkout } from '../../checkout';
import { color } from '../../../lib/themes';
import { FAQ } from '../../faq';
import { SelectNetwork } from '../../select-network';
import { Invoice, InvoiceStatus } from '../../../types/app';
import { getRemainingTime } from '../../../lib/helpers';
import { Formik } from 'formik';
import { commonAPI } from '../../../lib/api';
import { StatusBanner } from '../../../ui/status-banner';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MailIcon } from '../static/mail.svg';
import { ReactComponent as TelegramIcon } from '../static/telegram.svg';
import { Button } from '../../../ui/button';
import { ifProp } from 'styled-tools';


interface Props {
  setInvoice: React.Dispatch<React.SetStateAction<Invoice|null>>;
  invoice: Invoice;
}

interface IInitialValues {
  currency: number|null;
  network: number|null;
  currencies: any[];
}

const initialValues: IInitialValues = {
  currency: null,
  network: null,
  currencies: [],
};

export const PayForm: FC<Props> = ({ setInvoice, invoice }) => {
  const [t] = useTranslation();
  const [expiration, setExpiration] = useState(getRemainingTime(invoice))
  const [isFAQVisible, setFAQVisible] = useState(false);

  const isCheckoutVisible = !!(invoice.wallet && invoice.currency) || [InvoiceStatus.PAID, InvoiceStatus.OVERPAID, InvoiceStatus.PAID_PARTIALLY].includes(invoice.status);

  const onToggleFAQ = () => setFAQVisible(!isFAQVisible);

  const onUpdatePaymentMethod = async (values: IInitialValues) => {
    if (!values.currency || !values.network) {
      return;
    }

    const updateResult = await commonAPI.updateInvoiceCurrency({
      currency: values.currency,
      network: values.network,
    }, { invoiceId: invoice.id });

    setInvoice(updateResult);
  };

  const handleBackToMerchant = async () => {
    if (invoice.status === InvoiceStatus.PAID_PARTIALLY_WAITING) {
      await commonAPI.updateInvoiceCurrency({
        is_expired: true,
      }, { invoiceId: invoice.id });
    }

    window.location.href = invoice.branding.success_url || invoice.branding.site_url;
  };

  const renderInvoiceBanner = () => {
    if (invoice.status === InvoiceStatus.EXPIRED) {
      return (
        <StatusBanner type="danger" title={t('banner.expired.title')}>
          {t('banner.expired.description')}
        </StatusBanner>
      )
    }

    if ([InvoiceStatus.PAID_PARTIALLY_WAITING, InvoiceStatus.PAID_PARTIALLY].includes(invoice.status)) {
      let textKey = 'description';

      if (invoice.status === InvoiceStatus.PAID_PARTIALLY_WAITING && invoice.branding.is_partial_manual_debit_allowed) {
        textKey = 'description_closable';
      }

      return (
        <StatusBanner type={invoice.status === InvoiceStatus.PAID_PARTIALLY_WAITING ? "warning" : "success"} title={t('banner.paid_partially.title')}>
          {t(`banner.paid_partially.${textKey}`)}
        </StatusBanner>
      );
    }

    if (invoice.status === InvoiceStatus.PAID) {
      return (
        <StatusBanner type="success" title={t('banner.paid.title')}>
          {t('banner.paid.description')}
        </StatusBanner>
      )
    }

    if (invoice.status === InvoiceStatus.OVERPAID) {
      return (
        <StatusBanner type="success" title={t('banner.over_paid.title')}>
          {t('banner.over_paid.description')}
        </StatusBanner>
      )
    }

    return null;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = getRemainingTime(invoice);

      if (remainingTime.isExpired) {
        clearInterval(interval);
        return;
      }

      setExpiration(remainingTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <FormContainer>
      <Container>
        <PayContent>
          <Content>
            <ContentWrapper>
              <Header
                handleFaqClick={onToggleFAQ}
                invoice={invoice}
              />

              {isFAQVisible ? (
                <FAQ handleBack={onToggleFAQ}/>
              ) : (
                <Formik initialValues={initialValues} onSubmit={onUpdatePaymentMethod}>
                  {() => (
                    <>
                      {renderInvoiceBanner()}

                      <PaymentDetails
                        isCheckoutVisible={isCheckoutVisible}
                        isCompleted={[InvoiceStatus.PAID, InvoiceStatus.OVERPAID, InvoiceStatus.EXPIRED, InvoiceStatus.PAID_PARTIALLY].includes(invoice.status)}
                        expiration={expiration}
                        invoice={invoice}
                      />

                      {[InvoiceStatus.PAID, InvoiceStatus.OVERPAID, ...(invoice.branding.is_partial_manual_debit_allowed ? [InvoiceStatus.PAID_PARTIALLY_WAITING, InvoiceStatus.PAID_PARTIALLY] : [])].includes(invoice.status) && (
                        <BackButton
                          onClick={handleBackToMerchant}
                          className="secondary medium"
                          $isPartial={invoice.status === InvoiceStatus.PAID_PARTIALLY_WAITING}
                        >
                          {t('back_to_merchant')}
                        </BackButton>
                      )}

                      {[InvoiceStatus.PAID_PARTIALLY_WAITING, InvoiceStatus.PENDING, InvoiceStatus.PENDING_CONFIRMATIONS].includes(invoice.status) && (
                        <ContentActions>
                          {isCheckoutVisible ? <Checkout invoice={invoice} expiration={expiration}/> :
                            <SelectNetwork invoiceId={invoice.id}/>}
                        </ContentActions>
                      )}
                    </>
                  )}
                </Formik>
              )}
            </ContentWrapper>
          </Content>
        </PayContent>
        <div>
          <HelpContentContainer>
            <HelpDescription>{t('help_description')}</HelpDescription>
            <IconsContainer>
              <HelpIcon href="https://t.me/silus_supp" target="_blank">
                <TelegramIcon />
              </HelpIcon>
              <HelpIcon href="mailto:support@silus.io">
                <MailIcon />
              </HelpIcon>
            </IconsContainer>
          </HelpContentContainer>
        </div>
      </Container>
    </FormContainer>
  );
}

const FormContainer = styled.div`
    background-color: ${color('secondary')};
    min-height: 100vh;
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`;

const PayContent = styled.div`
    box-sizing: border-box;
    color: ${color('primary')};
    display: flex;
    flex-direction: column;
    max-width: 578px;
    width: 100%;

    border-radius: 24px;
    box-shadow: ${color('sElevation_4')};
    margin-top: 80px;

    @media (max-width: 768px) {
        margin-top: 0;
    }
`;

const Content = styled.div`
    box-sizing: border-box;
    color: ${color('primary')};
    display: flex;
    flex-direction: column;
    max-width: 578px;
    width: 100%;

    border-radius: 24px;
    box-shadow: ${color('sElevation_4')};
`;

const ContentWrapper = styled.div`
    background: ${color('payform')};
    display: flex;
    flex-direction: column;
    gap: 32px;

    border-radius: 24px;
    //padding-bottom: 32px;

    @media (max-width: 768px) {
        border-radius: 0;
    }
`;

const ContentActions = styled.div`
    background-color: ${color('Dark_bg1')};
    border-radius: 0 0 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;

    @media (max-width: 768px) {
        border-radius: 0;
        padding: 32px 16px;
    }
`;

const HelpContentContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
`;

const HelpDescription = styled.p`
    color: ${color('textSecondary')};
    display: block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.08px;
    line-height: 20px;
    margin-bottom: 4px;
    padding: 0 12px;
`;

const IconsContainer = styled.div`
    align-items: center;
    column-gap: 16px;
    display: flex;
`;

const HelpIcon = styled.a`
    align-items: center;
    background: ${color('backgroundApp')};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 44px;
    justify-content: center;
    width: 44px;
    
    svg {
        :first-child {
            fill: ${color('primary')};
        }

        :last-child {
            fill: ${color('secondary')};
        }
    }
`;

const BackButton = styled(Button)<{ $isPartial: boolean }>`
    background: ${p => color(`${p.$isPartial ? 'warning' : 'success'}-${p.theme.theme}`)(p)} !important;
    width: 100%;

    ${ifProp('$isPartial', css`
        margin: 0 30px;
        
        width: calc(100% - 60px);
    `, css`
        border-radius: 0 0 10px 10px;
    `)}

    text-decoration: none;

    color: ${p => p.theme.theme === 'dark' ? undefined : color('white')(p)} !important;

    &:hover {
        opacity: 0.85 !important;
    }
`;
import styled from 'styled-components';
import { color } from '../lib/themes';

export const Button = styled.button`
    align-items: center;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    padding: 18px 16px;
    transition: all .3s ease;
    width: 100%;

    svg {
        margin-inline-end: 10px;
        height: 20px;
        width: 20px
    }

    .button-spin svg {
        -webkit-margin-end: -10px;
        margin-inline-end: -10px
    }

    &:disabled {
        cursor: inherit;
        opacity: .36;
    }

    &.small {
        padding: 10px 16px
    }

    &.medium {
        padding: 14px 20px
    }

    &.large {
        padding: 18px 24px
    }

    &.primary {
        background: ${color('buttonPrimaryBackground')};
        color: ${color('secondary')};

        &:not(:disabled):hover {
            background: ${color('bsPrimaryHoverBackground')};
        }

        &:not(:disabled):active {
            background: ${color('bsPrimaryPressedBackground')};
        }
    }

    &.caption {
        background: ${color('buttonPrimaryBackground')};
        color: ${color('secondary')};
        font-weight: 500;
        margin-top: 16px;
        padding: 12px 20px;

        &:not(:disabled):hover {
            background: ${color('bsPrimaryHoverBackground')}
        }

        &:not(:disabled):active {
            background: ${color('bsPrimaryPressedBackground')}
        }
    }

    &.border {
        background: transparent;
        border: 1px solid ${color('primary')};
        color: ${color('primary')};

        &:not(:disabled):hover {
            background: ${color('btnSecondaryHover')}
        }

        &border:not(:disabled):active {
            background: ${color('btnSecondaryActive')}
        }

        &:disabled {
            background: ${color('btnSecondaryHover')}
        }
    }

    &.secondary {
        background: ${color('buttonSecondaryBackground')};
        color: ${color('primary')};

        &:not(:disabled):hover {
            background: ${color('bsSecondaryHoverBackground')}
        }

        &:not(:disabled):active {
            background: ${color('bsSecondaryPressedBackground')}
        }
    }

    &.tertiary {
        background: none;
        color: ${color('primary')};

        &:not(:disabled):hover {
            background: ${color('bsTertiaryHoverBackground')}
        }

        &:not(:disabled):active {
            background: ${color('bsTertiaryPressedBackground')}
        }
    }

    &.btn-primary {
        background-color: ${color('cPrimaryBackground')};
        color: ${color('textDisabled')};

        &:not(:disabled):hover {
            background-color: ${color('bsPrimaryHoverBackground')}
        }

        &:not(:disabled):active {
            background-color: ${color('bsPrimaryPressedBackground')}
        }

        &:disabled {
            background-color: ${color('cPrimaryBackground')};
            cursor: inherit;
            opacity: .36
        }
    }

    &.btn-secondary {
        background-color: ${color('cSecondaryBackground')};

        &:not(:disabled):hover {
            background-color: ${color('bsSecondaryHoverBackground')}
        }

        &:not(:disabled):active {
            background-color: ${color('bsSecondaryPressedBackground')}
        }

        &:disabled {
            cursor: inherit
        }
    }

    &.btn-tertiary {
        background: none;

        &:not(:disabled):hover {
            background-color: ${color('bsTertiaryHoverBackground')}
        }

        &:not(:disabled):active {
            background-color: ${color('bsTertiaryPressedBackground')}
        }

        &:disabled {
            cursor: inherit;
            opacity: .36
        }
    }
`;
import { useEffect, useState } from "react";

/* https://usehooks.com/useLocalStorage/ */
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key

      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item && item !== "undefined" ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue

      console.log(error);

      return initialValue;
    }
  });

  useEffect(() => {
    const valueToStore =
      storedValue instanceof Function ? storedValue(storedValue) : storedValue;

    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  }, [storedValue]);

  return [storedValue, setStoredValue];
}

export { useLocalStorage };

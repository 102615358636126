import { DefaultTheme } from 'styled-components';
import { lightTheme } from './light';

export const darkTheme: DefaultTheme = {
  theme: 'dark',
  colors: {
    ...lightTheme.colors,
    'primary': '#fff',
    'secondary': '#000',
    'negative': '#ff3d4d',
    'warning': '#ffa64d',
    'success': '#3ac270',
    'backgroundPrimary': '#000',
    'backgroundPrimaryFiat': '#373737',
    'backgroundSecondary': '#242424',
    'backgroundTertiary': '#373737',
    'backgroundInverted': '#fff',
    'backgroundApp': '#242424',
    'backgroundExpiration': '#141414',
    'backgroundCopyIcon': '#2d2d2d',
    'backgroundPayForm': '#242424',
    'backgroundSearchBar': '#242424',
    'backgroundTxID': 'linear-gradient(255deg, #282828 3.88%, #232323)',
    'bg_buttonUp': '#292929',
    'warning-lighter-alpha': 'rgba(255, 166, 77, .24)',
    'danger-lighter-alpha': 'rgba(255, 61, 77, .24)',
    'backgroundNegativeLightOverlay': 'rgba(255, 61, 77, .24)',
    'neutral-alpha-92': 'hsla(0, 0%, 100%, .92)',
    'borderPrimarySolid': 'hsla(0, 0%, 100%, .1)',
    'borderCatalogWhiteLine': '#2d2d2d',
    'buttonPrimaryBackground': '#fff',
    'buttonPrimaryForeground': '#000',
    'buttonSecondaryBackground': '#242424',
    'buttonSecondaryForeground': '#000',
    'buttonTertiaryBackground': '#000',
    'buttonHeaderHover': '#151515',
    'buttonHeaderActive': '#191919',
    'bsPrimaryHoverBackground': 'hsla(0, 0%, 100%, .6)',
    'bsPrimaryHoverForeground': '#fff',
    'bsPrimaryPressedBackground': 'hsla(0, 0%, 100%, .3)',
    'bsPrimaryPressedForeground': '#fff',
    'bsSecondaryHoverBackground': 'hsla(0, 0%, 100%, .16)',
    'bsSecondaryHoverForeground': '#fff',
    'bsSecondaryPressedBackground': 'hsla(0, 0%, 100%, .1)',
    'bsSecondaryPressedForeground': '#000',
    'bsTertiaryPressedBackground': 'hsla(0, 0%, 100%, .08)',
    'bsTertiaryHoverBackground': 'hsla(0, 0%, 100%, .04)',
    'backgroundAlpha_1': 'hsla(0, 0%, 100%, .14)',
    'backgroundAlpha_2': 'hsla(0, 0%, 100%, .16)',
    'backgroundAlpha_3': 'hsla(0, 0%, 100%, .18)',
    'backgroundAlpha_4': 'hsla(0, 0%, 100%, .24)',
    'backgroundAlpha_5': 'hsla(0, 0%, 100%, .32)',
    'backgroundAlpha_6': 'hsla(0, 0%, 100%, .8)',
    'backgroundAlpha_7': 'hsla(0, 0%, 100%, .9)',
    'backgroundAlpha_8': 'rgba(0, 0, 0, .32)',
    'backgroundAlpha_9': 'rgba(0, 0, 0, .012)',
    'backgroundPayformHint': 'hsla(0, 0%, 100%, .14)',
    'border_lang': 'hsla(0, 0%, 100%, .3)',
    'contentPrimary': '#fff',
    'contentSecondary': '#c5c5c5',
    'contentTertiary': '#888',
    'contentDisabled': '#2d2d2d',
    'iconSecondary': '#c5c5c5',
    'sElevation_3': '0px 8px 16px hsla(0, 0%, 100%, .06), 0px 0px 10px hsla(0, 0%, 100%, .2)',
    'borderAlpha_1': 'hsla(0, 0%, 100%, .3)',
    'borderAlpha_Inverted_1': 'rgba(0, 0, 0, .1)',
    'borderAlpha_2': '#444',
    'payform': 'linear-gradient(255.05deg, #282828 3.88%, #232323)',
    'payformCheckout': 'linear-gradient(255.05deg, #282828 3.88%, #232323)',
    'Dark_bg1': '#141414',
    'scrollbar_track': '#2d2d2d',
  }
}
import ru from './ru.json';
import en from './en.json';
import tr from './tr.json';
import ge from './ge.json';

export const i18nResources = {
  ru,
  en,
  tr,
  ge,
};
import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  theme: 'light',
  colors: {
    'black': '#000',
    'white': '#fff',
    'primary': '#000',
    'secondary': '#fff',
    'negative': '#ff3d4d',
    'backgroundPrimary': '#f6f6f6',
    'backgroundPrimaryFiat': '#f6f6f6',
    'backgroundPrimaryOverlay': 'rgba(0, 0, 0, .03)',
    'backgroundInverted': '#000',
    'backgroundSecondary': '#ebebeb',
    'backgroundApp': '#fff',
    'backgroundExpiration': '#fff',
    'backgroundCopyIcon': '#fff',
    'backgroundPayForm': '#f8f8f8',
    'backgroundSearchBar': '#f6f6f6',
    'backgroundTxID': '#fff',
    'backgroundTertiary': '#ebebeb',
    'bg_buttonUp': '#f6f6f6',
    'backgroundNegativeLightOverlay': 'rgba(255, 61, 77, .06)',
    'backgroundNegativeOverlay': 'rgba(255, 61, 77, .16)',
    'backgroundSuccessLightOverlay': 'rgba(58, 194, 112, .1)',
    'backgroundSuccessOverlay': 'rgba(58, 194, 112, .16)',
    'backgroundWarningLightOverlay': 'rgba(255, 166, 77, .06)',
    'backgroundWarningOverlay': 'rgba(255, 166, 77, .16)',
    'backgroundNoticeOverlay': '#ffa64d',
    'borderPrimarySolid': 'rgba(0, 0, 0, .1)',
    'borderPrimaryOverlay': 'rgba(0, 0, 0, .1)',
    'borderCatalogWhiteLine': '#e6e6e6',
    'backgroundAlpha_1': 'rgba(0, 0, 0, .14)',
    'backgroundAlpha_2': 'rgba(0, 0, 0, .16)',
    'backgroundAlpha_3': 'rgba(0, 0, 0, .18)',
    'backgroundAlpha_4': 'rgba(0, 0, 0, .24)',
    'backgroundAlpha_5': 'rgba(0, 0, 0, .32)',
    'backgroundAlpha_6': 'rgba(0, 0, 0, .8)',
    'backgroundAlpha_7': 'rgba(0, 0, 0, .9)',
    'backgroundAlpha_8': 'hsla(0, 0%, 100%, .32)',
    'backgroundAlpha_9': 'hsla(0, 0%, 100%, .012)',
    'backgroundPayformHint': 'rgba(0, 0, 0, .03)',
    'textSecondary': '#747474',
    'textPlaceholder': '#a3a3a3',
    'textDisabled': '#f6f6f6',
    'contentSecondary': '#666',
    'contentTertiary': '#a3a3a3',
    'iconsPrimary': '#000',
    'iconsSecondary': '#9f9f9f',
    'iconsTertiary': '#d2d2d2',
    'contentDisabled': '#f6f6f6',
    'iconSecondary': '#a3a3a3',
    'buttonPrimaryBackground': '#000',
    'buttonPrimaryForeground': '#fff',
    'buttonSecondaryBackground': 'rgba(0, 0, 0, .08)',
    'buttonSecondaryForeground': '#000',
    'buttonTertiaryBackground': '#000',
    'buttonHeaderHover': '#e6e6e6',
    'buttonHeaderActive': '#e3e3e3',
    'bsPrimaryHoverBackground': 'rgba(0, 0, 0, .6)',
    'bsPrimaryHoverForeground': '#fff',
    'bsPrimaryPressedBackground': 'rgba(0, 0, 0, .7)',
    'bsPrimaryPressedForeground': '#fff',
    'bsSecondaryHoverBackground': 'rgba(0, 0, 0, .16)',
    'bsSecondaryHoverForeground': '#000',
    'bsSecondaryPressedBackground': 'rgba(0, 0, 0, .12)',
    'bsSecondaryPressedForeground': '#000',
    'bsTertiaryHoverBackground': 'rgba(0, 0, 0, .04)',
    'bsTertiaryHoverForeground': '#000',
    'bsTertiaryPressedBackground': 'rgba(0, 0, 0, .08)',
    'bsTertiaryPressedForeground': '#000',
    'cPrimaryBackground': '#000',
    'cPrimaryForeground': '#fff',
    'cSecondaryBackground': 'rgba(0, 0, 0, .08)',
    'cSecondaryForeground': '#000',
    'neutral-background': '#fff',
    'neutral-1': '#fafafa',
    'neutral-3': '#e0e0e0',
    'neutral-2': '#f0f0f0',
    'neutral-4': '#a3a3a3',
    'neutral-5': '#5c5c5c',
    'neutral-6': '#333',
    'neutral-7': '#141414',
    'neutral-8': '#000',
    'neutral-alpha-background': 'rgba(0, 0, 0, .02)',
    'neutral-alpha-02': 'rgba(0, 0, 0, .02)',
    'neutral-alpha-06': 'rgba(0, 0, 0, .06)',
    'neutral-alpha-12': 'rgba(0, 0, 0, .12)',
    'neutral-alpha-36': 'rgba(0, 0, 0, .36)',
    'neutral-alpha-64': 'rgba(0, 0, 0, .64)',
    'neutral-alpha-80': 'rgba(0, 0, 0, .8)',
    'neutral-alpha-92': 'rgba(0, 0, 0, .92)',
    'neutral-alpha-100': '#000',
    'panel-neutral-100': '#fff',
    'panel-neutral-alpha-64': 'hsla(0, 0%, 100%, .64)',
    'panel-neutral-alpha-12': 'hsla(0, 0%, 100%, .12)',
    'success-lighter-alpha': 'rgba(58, 194, 112, .12)',
    'success-lighter': '#e7f7ed',
    'success-light': '#3ac270',
    'success': '#2aa668',
    'success-dark': '#127053',
    'success-alpha': '58, 194, 112',
    'warning-lighter-alpha': 'rgba(247, 200, 84, .12)',
    'warning-lighter': '#fef8ea',
    'warning-light': '#f7c854',
    'warning': '#f2ae1d',
    'warning-dark': '#d08e15',
    'danger-lighter-alpha': 'rgba(255, 26, 45, .12)',
    'danger-lighter': '#ffe3e5',
    'danger-fail': 'rgba(255, 61, 77, .06)',
    'danger-light': '#ff1a2d',
    'danger': '#dc1429',
    'danger-dark': '#b91026',
    'info-lighter-alpha': 'rgba(33, 177, 255, .12)',
    'info-lighter': '#e4f5ff',
    'info-light': '#21b1ff',
    'info': '#188adb',
    'info-dark': '#1067b7',
    'sElevation_1_bordered': '0px 0px 0px 1px #f5f5f5, 0px 8px 16px rgba(41, 41, 41, .04), 0px 0px 6px rgba(41, 41, 41, .04)',
    'sElevation_3': '0px 0px 20px rgba(0, 0, 0, .1), 0px 0px 6px rgba(0, 0, 0, .04)',
    'sElevation_4': '0px 0px 80px rgba(0, 0, 0, .1)',
    'borderAlpha_1': 'rgba(0, 0, 0, .1)',
    'borderAlpha_Inverted_1': 'hsla(0, 0%, 100%, .3)',
    'borderAlpha_2': '#e8e8e8',
    'border_lang': 'transparent',
    'payform': 'linear-gradient(247.95deg, #ededed 5.87%, #f6f6f6 96.55%)',
    'payformCheckout': '#fff',
    'Dark_bg1': '#fff',
    'scrollbar_track': '#7a7a7a',
    'btnSecondaryActive': 'rgba(0, 0, 0, 0.04)',
    'btnSecondaryHover': 'rgba(0, 0, 0, 0.03)',
  },
};
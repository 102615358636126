import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18nResources } from './resources';
import { formatNumber } from '../helpers';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.NODE_ENV === 'development',
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: i18nResources,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    ns: 'common',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      format: (value, format) => {

        if (format === 'formatPrice') return formatNumber(value,2);
        if (format === 'formatInteger') return formatNumber(value);

        return value;
      },
    }
  });

export { i18n };
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import toast from 'react-hot-toast';
import { ReactComponent as CopyIcon } from '../payment-details/static/copy.svg';
import { color } from '../../lib/themes';
import { FC } from 'react';
import { Invoice } from '../../types/app';

interface Props {
  invoice: Invoice;
}

export const CheckoutRecipientInfo: FC<Props> = ({ invoice }) => {
  const [t] = useTranslation();

  return (
    <CheckoutInfo>
      <QrWrapper>
        <QRCode
          value={invoice.wallet}
          level="L"
          size={104}
          bgColor="#fff"
          fgColor="#000"
        />
      </QrWrapper>
      <Recipient>
        <div>
          <RecipientTitle>{t('recipient_wallet')}</RecipientTitle>
          <RecipientAddress>
            <RecipientAddressText>{invoice.wallet}</RecipientAddressText>
            <AddressButtons>
              <CopyToClipboard text={invoice.wallet} onCopy={() => toast.success(t('address_copied'))}>
                <AddressCopyButton>
                  <CopyIcon/>
                </AddressCopyButton>
              </CopyToClipboard>
            </AddressButtons>
          </RecipientAddress>
        </div>
      </Recipient>
    </CheckoutInfo>
  );
};

const CheckoutInfo = styled.div`
    border-bottom: 1px solid ${color('borderPrimarySolid')};
    display: flex;
    padding: 16px;
`;

const QrWrapper = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    
    margin-right: 16px;

    @media (max-width: 768px) and (hover: none) {
        display: none;
    }
`;

const Recipient = styled.div`
    padding: 4px 0 0;
    width: 100%;
`;

const RecipientTitle = styled.div`
    color: ${color('textSecondary')};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.08px;
    line-height: 20px;
    margin-bottom: 2px;
    text-align: start;
`;

const RecipientAddress = styled.div`
    grid-gap: 4px;
    display: grid;
    gap: 4px;
    grid-template-columns: minmax(100px, auto) 1fr;
    margin-bottom: 8px;
`;

const RecipientAddressText = styled.span`
    color: ${color('contentPrimary')};
    display: block;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.12px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AddressButtons = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    height: 24px;
    justify-content: flex-end;
`;

const AddressCopyButton = styled.button`
    align-items: center;
    background: none;
    cursor: pointer;
    border: none;
    color: ${color('primary')};
    display: flex;
    height: 24px;
    justify-content: center;
    padding: 0;
    width: 24px;
`;

const CheckoutNotificationAlarm = styled.p`
    color: ${color('textSecondary')};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.08px;
    line-height: 20px;

    button {
        color: ${color('textSecondary')};
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -.08px;
        line-height: 20px;

        background: none;
        border: none;
        cursor: pointer;
        margin-bottom: 2px;
        outline: none;
        padding: 0;
        text-align: start;
        text-decoration: underline;
    }
`;

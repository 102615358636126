import styled from 'styled-components';
import { color } from '../../../../lib/themes';
import { Button } from '../../../button';
import { ReactComponent as ArrowLeftIcon } from './static/arrow-left.svg';
import { ReactComponent as CheckIcon } from './static/check.svg';
import { FC, useMemo, useState } from 'react';
import { Search } from '../../../search';
import { i18nResources } from '../../../../lib/i18next/resources';
import { useTranslation } from 'react-i18next';

interface Props {
  onBack(): void;
}

type TLanguageKey = keyof typeof i18nResources;

const languageTitles: Record<TLanguageKey, string> = {
  ru: 'Русский',
  en: 'English',
  tr: 'Türkçe',
  ge: 'ქართული',
}

export const LanguagesMenu: FC<Props> = ({ onBack }) => {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState<string|null>(null);

  const onSelectLanguage = (language: TLanguageKey) => {
    i18n.changeLanguage(language);
    onBack();
  };

  const filteredLanguages: TLanguageKey[] = useMemo(() => {
    const availableKeys = Object.keys(i18nResources) as TLanguageKey[];

    if (!searchValue) {
      return availableKeys;
    }

    return availableKeys.filter(key => (languageTitles[key] || '').includes(searchValue));
  }, [searchValue]);

  return (
    <Container>
      <BackButton onClick={onBack} className="premium medium">
        <ArrowLeftIcon /> {t('back')}
      </BackButton>
      <SearchContainer>
        <Search
          placeholder={t('search_placeholder')}
          onChange={setSearchValue}
        />
      </SearchContainer>
      <LanguageSelector>
        {filteredLanguages.map(language => (
          <LanguageContainer key={language} onClick={() => onSelectLanguage(language)}>
            <img width="24" height="24" src={`/flags/flag-${language}.svg`} alt={language} />
            <span>{languageTitles[language]}</span>
            {i18n.language === language && (
              <LanguageCheckmarkContainer>
                <CheckIcon />
              </LanguageCheckmarkContainer>
            )}
          </LanguageContainer>
        ))}
      </LanguageSelector>
    </Container>
  );
}

const Container = styled.div`
    background: ${color('backgroundCopyIcon')};
    display: flex;
    flex-direction: column;
`;

const BackButton = styled(Button)`
    background: transparent !important;
    color: ${color('primary')} !important;
    justify-content: flex-start;
    padding: 12px 16px !important;
    width: 100%;
`

const LanguageSelector = styled.ul`
    max-height: 240px;
    overflow-y: auto;
`;

const LanguageContainer = styled.li`
    align-items: center;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    position: relative;
    transition: all .3s ease;
    
    &:hover {
        background: ${color('backgroundSearchBar')};
    }
`;

const SearchContainer = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 12px 16px;
`;

const LanguageCheckmarkContainer = styled.div`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    
    svg {
        color: ${color('primary')};
        
        path {
            fill: ${color('contentTertiary')};
        }
    }
`;
import { FC, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';
import { ReactComponent as ArrowIcon } from './static/arrow.svg';
import { ReactComponent as LoadingIcon } from './static/loading.svg';
import { ReactComponent as SearchIcon } from './static/search.svg';
import { ReactComponent as ClearIcon } from './static/clear.svg';
import { color } from '../../lib/themes';
import { useOnClickOutside } from '../../lib/hooks';
import { useTranslation } from 'react-i18next';

interface SelectItem {
  label: string;
  icon?: string;
  value: string;
}

interface Props {
  label: string;
  name: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  items: SelectItem[];
  onChange(event: unknown): void;
  value: string;
}

export const Select: FC<Props> = ({
  name,
  label,
  items,
  value,
  isDisabled,
  isLoading,
  onChange,
}) => {
  const [t] = useTranslation('common');
  const ref = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpened, setIsOpened] = useState(false);

  const activeElement = items.find(item => item.value === value);
  const filteredItems = searchQuery ?
    items.filter(item => item.label.toLowerCase().includes(searchQuery.toLowerCase())) :
    items;

  const onSelect = (targetValue: string) => {
    if (isDisabled) {
      return;
    }

    onChange({ target: { name, value: targetValue }});
    setIsOpened(false);
  }

  useOnClickOutside(ref, () => setIsOpened(false));

  return (
    <Container ref={ref} className={isDisabled ? 'disabled' : undefined} $hasActions={!isDisabled && items.length > 1}>
      <SelectSelectorContainer $isLoading={isLoading} $isOpened={isOpened} onClick={() => setIsOpened(!isOpened)}>
        <SelectTextContainer>
          {activeElement ? (
            <ItemWrapper>
              {activeElement.icon && (
                <img src={activeElement.icon} alt={activeElement.label} />
              )}
              <span>{activeElement.label}</span>
            </ItemWrapper>
          ) : (
            <span>{label}</span>
          )}
        </SelectTextContainer>
        {isLoading ? <LoadingIcon /> : (!isLoading && !isDisabled && items.length > 1 && <ArrowIcon />)}
      </SelectSelectorContainer>
      <ItemsContainer>
        <ItemsList $isVisible={isOpened}>
          <SelectSearchContainer $isVisible={isOpened}>
            <SearchIcon />
            <SearchInput
              placeholder={t('search_placeholder')}
              onChange={e => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
            <ClearSearchButton $hasText={!!searchQuery.length} onClick={() => setSearchQuery('')}>
              <ClearIcon />
            </ClearSearchButton>
          </SelectSearchContainer>
          {filteredItems.map((selectItem, index) => (
            <ItemContainer key={index} onClick={() => onSelect(selectItem.value)}>
              <ItemWrapper>
                {selectItem.icon && (
                  <img src={selectItem.icon} alt={selectItem.label} />
                )}
                <span>{selectItem.label}</span>
              </ItemWrapper>
            </ItemContainer>
          ))}
        </ItemsList>
      </ItemsContainer>
    </Container>
  )
}

const Container = styled.div<{ $hasActions?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    align-self: center;
    position: relative;
    
    ${ifNotProp('$hasActions', css`
        pointer-events: none;
        cursor: initial;
    `)};
    
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
`;

const SelectSelectorContainer = styled.div<{ $isOpened?: boolean; $isLoading?: boolean }>`
    text-align: start;
    height: 60px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid ${color('borderPrimarySolid')};
    color: ${color('primary')};
    transition: all 0.3s ease-in-out 0s;

    cursor: pointer;
    
    svg {
        margin-right: 16px;
        margin-left: 16px;
        width: 24px;
        height: 24px;
        color: ${color('contentPrimary')};
        -webkit-box-flex: 0;
        flex-grow: 0;
        flex-shrink: 0;
        transition: transform 0.3s ease-in-out 0s;
        
        ${ifProp('$isLoading', css`
            animation: 0.5s linear 0s infinite normal none running ${spinAnimation};
        `)};
    }

    ${ifProp('$isOpened', css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        
        svg {
            transform: rotate(180deg);
        }
    `)};
`;

const SelectTextContainer = styled.div`
    -webkit-box-flex: 1;
    flex-grow: 1;
    overflow: hidden;
    
    & > span {
        padding: 12px 16px;
        font-size: 16px;
        line-height: 24px;
        color: unset;
    }
`;

const ItemsContainer = styled.div`
    position: relative;
    width: 100%;
`;

const ItemsList = styled.ul<{ $isVisible?: boolean }>`
    overflow: hidden auto;
    width: 100%;
    list-style: none;
    position: absolute;
    border-radius: 0 0 12px 12px;
    height: auto;
    max-height: 0;
    transition: max-height 0.3s ease-in-out 0s;
    background-color: ${color('backgroundApp')};
    z-index: 10;
    border-left: 1px solid ${color('borderPrimarySolid')};
    border-right: 1px solid ${color('borderPrimarySolid')};
    
    //&::-webkit-scrollbar {
    //    width: 0;
    //}
    //
    ${ifProp('$isVisible', css`
        max-height: 250px;
        border-bottom: 1px solid ${color('borderPrimarySolid')};
        border-top: none;
    `)};
`;

const ItemContainer = styled.li`
    height: 48px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    
    &:hover {
        background-color: ${color('backgroundAlpha_2')};
    }
`;

const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 12px 16px;
    gap: 8px;
    
    img {
        width: 24px;
        height: 24px;
    }
    
    span {
        text-transform: uppercase;
        color: ${color('contentPrimary')};
        white-space: nowrap;
    }
`;

const SelectSearchContainer = styled.div<{ $isVisible?: boolean }>`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    height: 48px;
    border-bottom: 1px solid ${color('borderPrimarySolid')};
    opacity: ${ifProp('$isVisible', 1, 0)};
    transition: opacity 0.3s ease-in-out 0s;
    
    svg {
        flex-shrink: 0;
    }
`;

const SearchInput = styled.input`
    font-size: 100%;
    line-height: 1.15;
    margin: 0;

    color: ${color('primary')};
    border: none;
    background-color: transparent;
    width: 50px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    
    &:focus {
        outline: none;
    }
`;

const ClearSearchButton = styled.button<{ $hasText?: boolean }>`
    border: none;
    background-color: transparent;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    opacity: ${ifProp('$hasText', 1, 0)};
    transform: translateX(${ifProp('$hasText', 0, '30px')});
    padding-top: 5px;
    
    svg {
        color: ${color('primary')};
    }
    
    &:hover svg path {
        fill: ${color('bsPrimaryHoverBackground')};
    }
`;
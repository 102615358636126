export const fillEndpointTemplate = (endpointTemplate: string, payload = {}) =>
  endpointTemplate.replace(/{([a-zA-Z]+)}/g, (match, key) => {
    // @ts-ignore
    const replaceValue = payload[key];
    if (replaceValue === undefined) {
      throw Error(`Can't find key for API template ${key} in payload`);
    }

    return replaceValue;
  });

export interface IRemainingTime {
  diffNowEnd: number;
  remainingPercent: number;
  timeLeft: string;
  isExpired: boolean;
}

export function getRemainingTime({ created_at: createdAt, expires_at: expiresAt }: { created_at: number; expires_at: number; }): IRemainingTime {
  const expiresAtDate = new Date(expiresAt * 1000),
    remainingTime = Date.now() - expiresAtDate.getTime(),
    diffStartEnd = (new Date(createdAt * 1000)).getTime() - expiresAtDate.getTime();

  const remainingPercent = (Math.abs(diffStartEnd) - Math.abs(remainingTime)) / Math.abs(diffStartEnd) * 100;

  return {
    remainingPercent,
    diffNowEnd: remainingTime,
    timeLeft: new Date(Math.abs(remainingTime)).toISOString().substr(11, 8),
    isExpired: remainingTime >= 0
  }
}

const formatNumberDecimals = (number = 0, decimals = 2, fractionLen = 3) => {
  const re = `\\d(?=(\\d{${fractionLen}})+${decimals > 0 ? "\\." : "$"})`;

  let finalNumber: string|number = Number(number);

  if (decimals) {
    finalNumber = finalNumber.toFixed(decimals)
  }

  return finalNumber.toString().replace(new RegExp(re, "g"), "$&  ");
};

/**
 * @param {integer} number: number
 * @param {integer} decimals: length of decimal
 * @param {integer} fractionLen: length of sections
 */
export const formatNumber = (number = 0, decimals = 4, fractionLen = 3) => {
  return formatNumberDecimals(number, decimals, fractionLen)
    .replace(/(\.\d+?)0{1,4}$/, '$1')
    .replace(/\.(0{1,4})?$/, '');
};;
import styled from 'styled-components';
import { CheckoutRecipientInfo } from '../../ui/checkout-recipient-info';
import { Expiration } from '../../ui/expiration';
import { PaymentStatus } from '../../ui/payment-status';
import { color } from '../../lib/themes';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { Invoice } from '../../types/app';
import { IRemainingTime } from '../../lib/helpers';

interface Props {
  invoice: Invoice;
  expiration: IRemainingTime;
}

export const Checkout: FC<Props> = ({ expiration, invoice }) => {
  return (
    <Container
      initial={{
        height: 0
      }}
      animate={{
        height: "auto"
      }}
      exit={{
        height: 0
      }}
    >
      <CheckoutRecipientInfo invoice={invoice} />
      <CheckoutStatus>
        <ExpirationContainer>
          <Expiration expiration={expiration} />
        </ExpirationContainer>
        <PaymentStatus pendingTransaction={invoice.pending_transaction} />
      </CheckoutStatus>
    </Container>
  )
}


const Container = styled(motion.div)`
    background: ${color('payformCheckout')};
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .04), 0 0 6px rgba(0, 0, 0, .04);
    display: flex;
    flex-direction: column;
    width: 100%;
`;


const ExpirationContainer = styled.div`
    align-items: center;
    border-right: 1px solid ${color('borderPrimarySolid')};
    display: flex;
    
    & > div {
        background: transparent;
    }
    
    @media (max-width: 768px) {
        border-right: none;
        border-bottom: 1px solid ${color('borderPrimarySolid')};
    }
`;


const CheckoutStatus = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;


import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useDebounce, useFirstRun } from './hooks';

export function SubmitOnChange({ delay = 1000 }) {
  const isFirstRun = useFirstRun();
  const { values, handleSubmit } = useFormikContext();

  const debouncedValues = useDebounce(values, delay);

  useEffect(() => {
    if (isFirstRun) {
      return;
    }

    handleSubmit();
  }, [debouncedValues]);

  return null;
}

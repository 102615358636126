import { FC, useEffect } from 'react';import { useTranslation } from 'react-i18next';
import { Field, FieldProps, useFormikContext } from 'formik';
import styled from 'styled-components';
import { Button } from '../../ui/button';
import { Select } from '../../ui/select';
import { Invoice } from '../../types/app';
import { commonAPI } from '../../lib/api';
import { CircularProgress } from '@mui/material';

interface Props {
  invoiceId: Invoice['id'];
}

export const SelectNetwork: FC<Props> = ({ invoiceId }) => {
  const [t] = useTranslation();
  const { values, handleSubmit, setFieldValue, isSubmitting } = useFormikContext<Record<string, any>>();

  const { networks, currencies } = values;

  useEffect(() => {
    commonAPI.getInvoiceCurrencies(null, { invoiceId: invoiceId })
      .then(resolvedCurrencies => {
        setFieldValue('currencies', resolvedCurrencies.map((currency: any) => ({
          label: currency.name,
          value: currency.enum,
          icon: currency.icon,
        })));

        if (resolvedCurrencies.length === 1) {
          setFieldValue('currency', resolvedCurrencies[0].enum);
        }

      });
  }, []);

  useEffect(() => {
    if (!values.currency) {
      return;
    }

    setFieldValue('networks', null);

    commonAPI.getInvoiceNetworks(null, {
      invoiceId: invoiceId,
      currency: values.currency!,
    })
      .then(resolvedNetworks => {
        setFieldValue('networks', resolvedNetworks.map((network: any) => ({
          label: `${network.name}${network.token_standard ? ` (${network.token_standard})` : ''}`,
          value: network.enum,
          amount: network.amount,
          amount_parts: network.amount_parts,
          fiat_amount: network.fiat_amount,
        })));

        if (resolvedNetworks.length === 1) {
          setFieldValue('network', resolvedNetworks[0].enum);
        }
      });
  }, [values.currency]);

  return (
    <Container>
      <SelectContainer onSubmit={handleSubmit}>
        <Field name="currency">
          {({field}: FieldProps) => (
            <Select
              {...field}
              label={t('select_currency')}
              isDisabled={!currencies.length}
              isLoading={!currencies.length}
              items={currencies}
            />
          )}
        </Field>

        <Field name="network">
          {({field}: FieldProps) => (
            <Select
              {...field}
              label={t('select_network')}
              isDisabled={!values.currency || !networks}
              isLoading={!!values.currency && !networks}
              items={networks || []}
            />
          )}
        </Field>

        <Button disabled={!values.currency || !values.network || isSubmitting} className="large primary" type="submit">
          {!isSubmitting ? t('proceed') : <CircularProgress size={20} thickness={4} />}
        </Button>
      </SelectContainer>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
`;

const SelectContainer = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    column-gap: 16px;
`;

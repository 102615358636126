import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { color } from '../../lib/themes';

interface Props {
  title: string;
  children: string;
}

export const ItemRow: FC<Props> = ({ title, children: text }) => {
  const [isOpened, setOpened] = useState(false);

  return (
    <Container onClick={() => setOpened(!isOpened)}>
      <HeaderContainer>
        <p>{title}</p>
        <PlusIcon $isOpened={isOpened} />
      </HeaderContainer>
      <DescriptionText
        variants={{
          open: {
            opacity: 1,
            height: "auto"
          },
          closed: {
            opacity: 0,
            height: 0
          },
        }}
        initial="closed"
        animate={isOpened ? "open" : "closed"}
        transition={{
          duration: 0.3,
          type: 'tween',
        }}
      >
        <span>{text}</span>
      </DescriptionText>
    </Container>
  );
}

const Container = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 24px 0;
    
    &:not(:last-of-type) {
        border-bottom: 1px solid ${color('borderCatalogWhiteLine')};
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    
    p {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
        max-width: 80%;
    }
`;

const PlusIcon = styled.div<{ $isOpened?: boolean }>`
    height: 16px;
    position: relative;
    width: 16px;
    
    &::before, &::after {
        background-color: ${color('primary')};
        border-radius: 2px;
        content: "";
        position: absolute;
        transition: all .3s ease;
    }
    
    &::before {
        height: 2px;
        left: 0;
        top: 7.5px;
        width: 16px;
    }
    
    &::after {
        height: 16px;
        left: 7px;
        top: 1px;
        transform: scaleX(1.1);
        -webkit-transform-origin: center;
        transform-origin: center;
        width: 2px;
        
        ${ifProp('$isOpened', css`
            transform: scaleY(0);
        `)};
    }
`;

const DescriptionText = styled(motion.div)`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.12px;
    line-height: 24px;
    
    span {
        padding-top: 16px;
    }
`;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { i18n } from './lib/i18next';
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);


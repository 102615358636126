import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { color } from '../../lib/themes';
import { ReactComponent as WarningIcon } from './warning.svg';
import { ReactComponent as SuccessIcon } from './success.svg';

interface Props {
  type: 'fail' | 'danger' | 'warning' | 'notice' | 'success' | 'default';
  title: string;
  children: string;
}

export const StatusBanner: FC<PropsWithChildren<Props>> = ({ type, title, children: text }) => {
  return (
    <Container>
      <Wrapper className={type}>
        <StatusIconContainer>
          {type === 'success' ? <SuccessIcon /> : <WarningIcon />}
        </StatusIconContainer>
        <HeadingText>{title}</HeadingText>
        <DescriptionText>{text}</DescriptionText>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
    padding: 0 32px;
    
    @media (max-width: 768px) {
        padding: 0 16px;
    }
`;

const StatusIconContainer = styled.span`
    height: 24px;
    width: 24px;
    
`;

const Wrapper = styled.div`
    grid-row-gap: 6px;
    grid-column-gap: 12px;
    border-radius: 12px;
    -webkit-column-gap: 12px;
    column-gap: 12px;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 12px;
    row-gap: 6px;

    &.danger {
        background-color: ${color('danger-lighter-alpha')};

        ${StatusIconContainer} {
            color: ${color('negative')};
        }
    }
    
    &.fail {
        background-color: ${color('danger-fail')};

        ${StatusIconContainer} {
            color: ${color('negative')};
        }
    }
    
    &.warning {
        background-color: ${color('backgroundWarningOverlay')};

        ${StatusIconContainer} {
            color: ${color('warning')};
        }
    }
    
    &.notice {
        background-color: ${color('backgroundWarningLightOverlay')};

        ${StatusIconContainer} {
            color: ${color('backgroundNoticeOverlay')};
        }
    }

    &.success {
        background-color: ${color('backgroundSuccessOverlay')};

        ${StatusIconContainer} {
            color: ${color('success')};
        }
    }
    
    &.default {
        background-color: ${color('backgroundPayformHint')};

        ${StatusIconContainer} {
            color: ${color('primary')};
        }
    }
`;

const HeadingText = styled.span`
    color: ${color('primary')};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
`;

const DescriptionText = styled.p`
    color: ${color('primary')};
    font-size: 16px;
    font-weight: 400;
    grid-column: 2;
    letter-spacing: -.12px;
    line-height: 24px;
`;
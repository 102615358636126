import { FC } from 'react';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import toast from 'react-hot-toast';
import { ifProp } from 'styled-tools';
import { color } from '../../lib/themes';
import { Remaining } from '../remaining';
import { IRemainingTime } from '../../lib/helpers';
import { useCurrency } from '../../lib/hooks/use-currency';
import { Invoice, InvoiceStatus } from '../../types/app';
import { Expiration } from '../expiration';
import { ReactComponent as CopyIcon } from './static/copy.svg';
import { ReactComponent as InfoIcon } from './static/info.svg';
import { ReactComponent as FAQIcon } from '../header/static/FAQ.svg';

interface Props {
  isCheckoutVisible: boolean;
  isCompleted: boolean;
  invoice: Invoice;
  expiration: IRemainingTime;
}

export const PaymentDetails: FC<Props> = ({
                                            expiration,
                                            invoice,
                                            isCheckoutVisible,
                                            isCompleted,
                                          }) => {
  const [t] = useTranslation();
  const translateCurrency = useCurrency();
  const {values} = useFormikContext<Record<string, any>>();

  const currentNetwork = invoice.network || (values.networks || []).find((network: any) => network.value === values.network);

  const cryptoAmount = isCheckoutVisible ? ({
    amount: invoice.crypto_amount,
    currency: invoice.currency.name,
  }) : (
    values.network && currentNetwork ? {
      amount: currentNetwork?.amount,
      currency: values.currencies.find((currency: any) => currency.value === values.currency).label,
    } : null);

  const amountParts = invoice.amount_parts || currentNetwork?.amount_parts;
  const hasPriceTooltip = !!amountParts;

  const fiatAmount = currentNetwork?.fiat_amount || invoice.amount;

  if (invoice.status === InvoiceStatus.EXPIRED) {
    return (
      <Container $isCompleted>
        <Wrapper>
          <AmountWrapper>
            <Amount>{translateCurrency(invoice.amount, invoice.fiat_currency)}</Amount>
          </AmountWrapper>
          <CopyToClipboard text={invoice.amount.toString()} onCopy={() => toast.success(t('amount_copied'))}>
            <CopyIconContainer>
              <CopyIcon className="icon-wrapper"/>
            </CopyIconContainer>
          </CopyToClipboard>
        </Wrapper>
      </Container>
    )
  }

  return (
    <Container $isCompleted={isCompleted}>
      <Wrapper>
        {cryptoAmount ? (
          <>
            <AmountWrapper>
              <Amount>{cryptoAmount.amount}</Amount>
              <Amount>{cryptoAmount.currency}</Amount>
            </AmountWrapper>
            <CopyToClipboard text={cryptoAmount.amount.toString()} onCopy={() => toast.success(t('amount_copied'))}>
              <CopyIconContainer>
                <CopyIcon className="icon-wrapper"/>
              </CopyIconContainer>
            </CopyToClipboard>
          </>
        ) : (
          <AmountWrapper>
            <Amount>{t('select_currency')}</Amount>
          </AmountWrapper>
        )}
      </Wrapper>
      <MenuContainer>
        <div>
          <Subheading>
            <SubheadingCurrency>
              <span>{translateCurrency(fiatAmount, invoice.fiat_currency)}</span>
              {!!(hasPriceTooltip && cryptoAmount) && (
                <Tooltip enterTouchDelay={0} title={(
                  <AmountPopupContainer>
                    <div>
                      <p>{t('exchange_rate')}</p>
                      <p>1 {cryptoAmount.currency} ≈ {translateCurrency(amountParts!.exchange_rate, invoice.fiat_currency)}</p>
                    </div>
                    {(amountParts?.network_fee > 0 || amountParts?.platform_fee > 0) && (
                      <div>
                        <p>{t('order_amount')}</p>
                        <p>{amountParts!.amount} {cryptoAmount.currency}</p>
                      </div>
                    )}
                    {amountParts!.network_fee > 0 && (
                      <div>
                        <p>{t('network_fee')}</p>
                        <p>{amountParts!.network_fee} {cryptoAmount.currency}</p>
                      </div>
                    )}
                    {amountParts!.platform_fee > 0 && (
                      <div>
                        <p>{t('platform_fee')}</p>
                        <p>{amountParts!.platform_fee} {cryptoAmount.currency}</p>
                      </div>
                    )}
                  </AmountPopupContainer>
                )} arrow placement="bottom">
                  <FAQIcon/>
                </Tooltip>
              )}
            </SubheadingCurrency>
            <span>{isCheckoutVisible ? `${t('network')} · ${currentNetwork.name}${!!currentNetwork.token_standard ? ` (${currentNetwork.token_standard})` : ''}` : t('select_network')}</span>
          </Subheading>
          <PaymentDetailsFee>
            <InfoIcon/>
            <span>{t('fee_notice')}</span>
          </PaymentDetailsFee>
        </div>
      </MenuContainer>
      {invoice.status === InvoiceStatus.PAID_PARTIALLY_WAITING && (
        <Remaining invoice={invoice}/>
      )}

      {invoice.status === InvoiceStatus.PENDING && !invoice.network && (
        <Expiration expiration={expiration}/>
      )}
    </Container>
  );
}

const Container = styled.div<{ $isCompleted?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 0 32px ${ifProp('$isCompleted', '32px')};

    @media (max-width: 768px) {
        padding: 0 16px ${ifProp('$isCompleted', '16px')};
    }
`;

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
`;

const AmountWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const Amount = styled.div`
    align-items: center;
    display: flex;
    font-size: 36px;
    font-weight: 600;
    gap: 8px;
    line-height: 44px;
    word-break: break-all;
`;

const CopyIconContainer = styled.div`
    background: ${color('backgroundCopyIcon')};
    border-radius: 40px;
    box-shadow: ${color('sElevation_3')};
    color: ${color('contentPrimary')};
    cursor: pointer;
    flex-shrink: 0;
    height: 44px;
    position: relative;
    transition: all .3s ease;
    width: 44px;

    svg {
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &:active {
        transform: scale(.85);
    }
`;

const MenuContainer = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    &:not(:last-child) {
        padding-bottom: 32px
    }
`;

const Subheading = styled.div`
    -webkit-column-gap: 6px;
    column-gap: 6px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
`;

const PaymentDetailsFee = styled.div`
    align-items: center;
    color: ${color('contentSecondary')};
    display: flex;
    gap: 8px;
    margin-top: 16px;
`;

const SubheadingCurrency = styled.div`
    margin-bottom: 8px;
    
    display: flex;
    align-items: center;
    gap: 5px;
    
    svg {
        width: 16px;
        height: 16px;
    }
`;

const AmountPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
    
  min-width: 212px;
    
  & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      
      & > :first-child {
          font-size: 16px;
      }
      
      & > :last-child {
          font-size: 18px;
          font-weight: 600;
      }
  }
`;
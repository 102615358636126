import styled from 'styled-components';
import { color } from '../../lib/themes';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import { IRemainingTime } from '../../lib/helpers';
import { prop } from 'styled-tools';

export const Expiration: FC<{ expiration: IRemainingTime }> = ({ expiration }) => {
  const [t] = useTranslation();

  const { timeLeft, remainingPercent } = expiration;

  const getColor = () => {
    if (remainingPercent > 90) {
      return color('negative');
    }

    if (remainingPercent > 75) {
      return color('warning');
    }

    return color('success');
  }

  return (
    <Wrapper>
      <IconContainer $color={getColor()}>
        <CircularProgress variant="determinate" value={100} />
        <CircularProgress variant="determinate" value={remainingPercent} />
      </IconContainer>
      <ProgressTextContainer>
        <LabelText>{t('expiration_time')}</LabelText>
        <TimeText $color={getColor()}>{timeLeft}</TimeText>
      </ProgressTextContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    background-color: ${color('backgroundExpiration')};
    border-radius: 16px;
    gap: 16px;
    padding: 16px;
    width: 100%;

    align-items: center;
    display: flex;
`;

const IconContainer = styled.div<{ $color: any }>`
    align-items: center;
    display: flex;
    position: relative;
    
    svg {
        color: ${color('backgroundSuccessOverlay')};
        
        circle {
            stroke-linecap: round;
        }
    }
    
    span:last-child {
        position: absolute;
        left: 0;

        svg {
            color: ${prop('$color')};
        }
    }
`;

const ProgressTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const LabelText = styled.span`
    color: ${color('contentSecondary')};
`;

const TimeText = styled.span<{ $color: any }>`
    color: ${prop('$color')};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 72px;
`;
import { useTranslation } from 'react-i18next';

export const useCurrency = () => {
  const [t] = useTranslation();

  return (amount: number, fiatCurrency: string) => {
    let translatedString = t('currency', { context: fiatCurrency.toLowerCase(), count: amount });

    if (translatedString === 'currency') {
      translatedString = `${amount} ${fiatCurrency}`;
    }

    return translatedString;
  }
}
import styled, { keyframes } from 'styled-components';
import { ReactComponent as ProgressIcon } from './static/progress.svg';
import { color } from '../../lib/themes';
import { useTranslation } from 'react-i18next';
import { Invoice } from '../../types/app';
import { FC, useMemo } from 'react';
import { prop } from 'styled-tools';

interface Props {
  pendingTransaction?: Invoice['pending_transaction'];
}

const statusEmojis = [
  require('./static/emoji/1.png'),
  require('./static/emoji/2.png'),
  require('./static/emoji/3.png'),
  require('./static/emoji/4.png'),
  require('./static/emoji/5.png'),
  require('./static/emoji/6.png'),
  require('./static/emoji/7.png'),
  require('./static/emoji/8.png'),
  require('./static/emoji/9.png'),
  require('./static/emoji/10.png'),
  require('./static/emoji/11.png'),
  require('./static/emoji/12.png'),
];

export const PaymentStatus: FC<Props> = ({ pendingTransaction }) => {
  const [t] = useTranslation();

  const {
    confirmations,
    required_confirmations: requiredConfirmations,
    explorer_link: explorerLink
  } = pendingTransaction || {};

  const currentEmoji = useMemo(() => {
    if (!pendingTransaction?.confirmations) {
      return null;
    }

    return statusEmojis[Math.floor(Math.random()*statusEmojis.length)];
  }, [pendingTransaction?.confirmations]);

  return (
    <Container>
      <ConfirmIconContainer>
        <ProgressIcon />
        {pendingTransaction && (
          <EmojiStatus
            $emoji={currentEmoji}
          />
        )}
      </ConfirmIconContainer>
      <StatusText {...(explorerLink ? { as: 'a', href: explorerLink, target: '_blank'} : {})}>
        {pendingTransaction ?
          t('payment_status.confirm_check', {
            count: confirmations,
            count2: requiredConfirmations,
          }) :
          t('payment_status.process')}
      </StatusText>
    </Container>
  );
};

const Container = styled.div`
    position: relative;

    align-items: center;
    display: flex;

    flex-direction: row;
    justify-self: center;
    width: 100%;
    padding: 16px;
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  
  100% {
      transform: rotate(1turn);
  }
`;

const ConfirmIconContainer = styled.div`
    align-items: center;
    position: relative;
    
    color: ${color('success')};
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 16px;
    
    & > :first-child {
        animation: ${rotateAnimation} 3s infinite;
        transform: rotate(180deg);
    }
`;

const StatusText = styled.span`
    color: ${color('contentSecondary')};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.12px;
    line-height: 24px;
`;

const EmojiStatus = styled.div<{ $emoji: string }>`
  width: 24px;
  height: 24px;
  background: url("${prop('$emoji')}") no-repeat;
  background-size: contain;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
`;
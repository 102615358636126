import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../static/arrow.svg';
import { color } from '../../../../lib/themes';

interface Props {
  icon: FC;
  title: string;
  children?: string;
  onClick?(): void;
}

export const MenuItem: FC<Props> = ({ icon: Icon, title, children, onClick }) => {
  return (
    <Container onClick={onClick}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <ContentContainer>
        <Title>{title}</Title>
        {children && (
          <Subtitle>{children}</Subtitle>
        )}
      </ContentContainer>
      <MenuItemArrowContainer>
        <ArrowIcon />
      </MenuItemArrowContainer>
    </Container>
  )
}


const Container = styled.div`
    align-items: center;
    background: ${color('backgroundCopyIcon')};
    -webkit-column-gap: 11px;
    column-gap: 11px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 16px;
    position: relative;
    transition: all .3s ease;
    width: 100%;
    
    &:hover {
        background: ${color('backgroundSecondary')};
    }
`;

const IconContainer = styled.div`
    align-items: center;
    color: ${color('primary')};
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    
    svg {
        color: ${color('primary')};
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2px;
`;

const Title = styled.p`
    color: ${color('primary')};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.12px;
    line-height: 24px;
`;

const Subtitle = styled.span`
    color: ${color('contentSecondary')};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.08px;
    line-height: 20px;
`;

const MenuItemArrowContainer = styled.div`
    height: 24px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 24px;
`;
import { createEndpoint } from './common';
import { Invoice } from '../../types/app';

interface UpdateInvoiceCurrencyRequest {
  currency: number;
  network: number;
}

export const commonAPI = {
  getInvoice: createEndpoint<Invoice, null, { invoiceId: string }>('{invoiceId}'),
  getInvoiceCurrencies: createEndpoint<any, null, { invoiceId: string }>('{invoiceId}/currencies'),
  getInvoiceNetworks: createEndpoint<any, null, { invoiceId: string; currency: string }>('{invoiceId}/currencies/{currency}'),
  updateInvoiceCurrency: createEndpoint<Invoice, UpdateInvoiceCurrencyRequest | { is_expired: true }, { invoiceId: string }>('{invoiceId}', 'PUT'),
}
import { createGlobalStyle } from 'styled-components';
import { color } from '../themes';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: none;
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body.dark {
  
    }

    * {
        box-sizing:border-box
    }

    html {
        -webkit-text-size-adjust: 100%;
        line-height:1.15
    }

    body {
        margin:0
    }

    body.dark .MuiSkeleton-root {
        background: ${color('backgroundAlpha_4')}
    }

    body.dark .MuiDivider-root {
        background-color: ${color('backgroundAlpha_5')}
    }

    main {
        display:block
    }

    h1 {
        font-size: 2em;
        margin:.67em 0
    }

    hr {
        box-sizing: initial;
        height: 0;
        overflow:visible
    }

    pre {
        font-family: monospace, monospace;
        font-size:1em
    }

    a {
        background-color:transparent
    }

    abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration:underline dotted
    }

    b, strong {
        font-weight:bolder
    }

    code, kbd, samp {
        font-family: monospace, monospace;
        font-size:1em
    }

    small {
        font-size:80%
    }

    sub, sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align:initial
    }

    sub {
        bottom:-.25em
    }

    sup {
        top:-.5em
    }

    img {
        border-style:none
    }

    button:focus, input:focus, select:focus, textarea:focus {
        outline:none
    }

    button, input, optgroup, select, textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin:0
    }

    button, input {
        overflow:visible
    }

    button, select {
        text-transform:none
    }

    [type=button], [type=reset], [type=submit], button {
        -webkit-appearance:button
    }

    [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
        border-style: none;
        padding:0
    }

    [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
        outline:1px dotted ButtonText
    }

    fieldset {
        padding:.35em .75em .625em
    }

    legend {
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space:normal
    }

    progress {
        vertical-align:initial
    }

    textarea {
        overflow:auto
    }

    [type=checkbox], [type=radio] {
        padding:0
    }

    [type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
        height:auto
    }

    [type=search] {
        -webkit-appearance: textfield;
        outline-offset:-2px
    }

    [type=search]::-webkit-search-decoration {
        -webkit-appearance:none
    }

    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font:inherit
    }

    details {
        display:block
    }

    summary {
        display:list-item
    }

    [hidden], template {
        display:none
    }

    body {
        font-family:Golos
    }

    ol, ul {
        list-style:none outside none
    }

    a, abbr, acronym, address, applet, b, big, blockquote, body, caption, center, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, u, ul, var {
        background: none repeat scroll 0 0 transparent;
        border: 0;
        font-size: 100%;
        margin: 0;
        outline: 0 none;
        padding: 0;
        vertical-align:initial
    }

    .snack-close {
        color:inherit !important
    }

    body {
        background-color: ${color('secondary')}
    }

    body {
        font-family: Golos, sans-serif;
    }

    body .MuiTooltip-tooltip {
        border-radius: 10px;
        padding: 8px 12px;
        opacity: 1;
        background: ${color('primary')};
        color: ${color('secondary')};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        left: 0;
        display: flex;
        row-gap: 8px;
        flex-direction: column;
        max-width: 367px;

        .MuiTooltip-arrow {
            opacity: 1;
            color: ${color('primary')};
            top: 98%;
            width: 24px;
            height: 12px;
            left: 0px;
        }
    }
    
    .MuiCircularProgress-svg {
        color: ${color('bg_buttonUp')};
    }

    .go2344853693 {
        background: ${color('success')};
    }
`;
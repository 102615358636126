import styled from 'styled-components';
import { motion } from 'framer-motion';
import { color } from '../../lib/themes';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as ClearIcon } from './clear.svg';
import { Field, Formik } from 'formik';
import { SubmitOnChange } from '../../lib/submit-on-change';
import { FC } from 'react';

interface Props {
  placeholder: string;
  onChange(value: string): void;
}

export const Search: FC<Props> = ({ placeholder, onChange }) => {
  return (
    <Formik initialValues={{ search: '' }} onSubmit={values => onChange(values.search)}>
      {({ values, setFieldValue }) =>
        (
          <SearchWrapper>
            <SubmitOnChange delay={250}/>

            <SearchIconContainer>
              <SearchIcon />
            </SearchIconContainer>
            <Field
              name="search"
              render={({field}: { field: Record<string, any> }) => (
                <SearchInput
                  {...field}
                  placeholder={placeholder}
                />
              )}
            />
            <ClearIconContainer>
              <ClearIconWrapper
                onClick={() => setFieldValue('search', '')}
                variants={{
                  open: {
                    opacity: 1,
                    x: 0,
                  },
                  closed: {
                    opacity: 0,
                    x: 20,
                  }
                }}
                initial="closed"
                exit="closed"
                animate={values.search.length > 0 ? "open" : "closed"}
                transition={{
                  duration: 0.15,
                  bounce: false,
                }}
              >
                <ClearIcon />
              </ClearIconWrapper>
            </ClearIconContainer>
          </SearchWrapper>
        )}
    </Formik>
  )
}

const SearchWrapper = styled.div`
    grid-gap: 16px;
    border: 1px solid transparent;
    border-radius: 12px;
    cursor: text;
    display: grid;
    gap: 16px;
    grid-template-columns: 24px 1fr 24px;
    overflow: hidden;
    padding: 8px 12px;
    transition: all .3s ease;

    background-color: ${color('backgroundSearchBar')};
    
    &:focus-within {
        background-color: ${color('secondary')};
        border: 1px solid ${color('backgroundAlpha_2')};
    }
`;

const SearchIconContainer = styled.div`
    align-items: center;
    color: ${color('contentTertiary')};
    display: flex;
    height: 24px;
    justify-content: center;
    overflow: hidden;
    width: 24px;
    
    svg {
        color: ${color('contentTertiary')};
    }
`;

const SearchInput = styled.input`
    background: transparent;
    border: none;
    color: ${color('primary')};
    font-size: 16px;
    font-weight: 400;
    height: 100%;
    letter-spacing: -.12px;
    line-height: 24px;
    width: 100%;
`;

const ClearIconContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 24px;
    min-width: 24px;
    overflow: hidden;
`;

const ClearIconWrapper = styled(motion.span)`
    align-items: center;
    color: ${color('primary')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all .3s ease;
    
    svg {
        color: ${color('primary')};
    }
`;
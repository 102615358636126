import { FC, PropsWithChildren, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { color } from '../../lib/themes';
import { MenuItem } from './organisms/menu-item';
import { useOnClickOutside } from '../../lib/hooks';
import { ReactComponent as LanguageIcon } from './static/language.svg';
import { LanguagesMenu } from './organisms/languages-menu';

export const Menu: FC<PropsWithChildren> = ({children}) => {
  const ref = useRef(null);
  const [t] = useTranslation();
  const [isLanguagesActive, setLanguagesActive] = useState(false);
  const [isOpen, setOpened] = useState(false);

  useOnClickOutside(ref, () => {
    setOpened(false);
    setTimeout(() => {
      setLanguagesActive(false);
    }, 300);
  });

  return (
    <Container ref={ref}>
      <div onClick={() => setOpened(!isOpen)}>
        {children}
      </div>
      <AnimatePresence mode="wait">
        {isOpen && (
          <MenuContainer
            key="menu"
            variants={{
              open: {
                opacity: 1,
                y: 0,
              },
              closed: {
                opacity: 0,
                y: -20,
              },
            }}
            initial="closed"
            exit="closed"
            animate="open"
            transition={{
              duration: 0.3,
              type: 'tween',
            }}
          >
            {isLanguagesActive ? (
              <LanguagesMenu onBack={() => setLanguagesActive(false)}/>
            ) : (
              <MenuItem icon={LanguageIcon} title={t('menu.language.title')} onClick={() => setLanguagesActive(true)}>
                {t('menu.language.subtitle')}
              </MenuItem>
            )}
          </MenuContainer>
        )}
      </AnimatePresence>
    </Container>
  )
}

const Container = styled.div`
    position: relative;
`;

const MenuContainer = styled(motion.div)`
    border: 1px solid ${color('borderCatalogWhiteLine')};
    border-radius: 16px;
    box-shadow: -10px 0 80px 0 rgba(0, 0, 0, .1);
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 48px;
    width: 343px;
    z-index: 10;
`;
import styled from 'styled-components';
import { color } from '../../lib/themes';
import { FC } from 'react';
import { Invoice } from '../../types/app';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarnIcon } from './warn.svg';

export const Remaining: FC<{ invoice: Invoice }> = ({ invoice }) => {
  const [t] = useTranslation();

  const {
    crypto_amount: cryptoAmount,
    remaining_crypto_amount: remainingCryptoAmount,
    currency,
  } = invoice;

  return (
    <Container>
      <WarnIcon />
      <Wrapper>
        <CellContainer>
          <CellValue>{t('invoice_amount')}</CellValue>
          <CellAmount>{cryptoAmount} {currency.name}</CellAmount>
        </CellContainer>
        <CellContainer>
          <CellValue>{t('paid_amount')}</CellValue>
          <CellAmount>{parseFloat((cryptoAmount - remainingCryptoAmount).toFixed(8))} {currency.name}</CellAmount>
        </CellContainer>
        <CellContainer>
          <CellValue>{t('left_amount')}</CellValue>
          <CellAmount>{remainingCryptoAmount} {currency.name}</CellAmount>
        </CellContainer>
        {invoice.branding.is_partial_manual_debit_allowed && (
          <CellContainer>
            <CellValue>{t('merchant_partial_warning')}</CellValue>
          </CellContainer>
        )}
      </Wrapper>
    </Container>
  )
};

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    background-color: ${color('Dark_bg1')};
    border-radius: 16px;
    
    padding-left: 16px;
    
    svg {
        width: 32px;
        height: 32px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 16px 16px 0;
    width: 100%;
`;

const CellContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CellValue = styled.span`
    color: ${color('textSecondary')};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.08px;
    line-height: 20px;
`;

const CellAmount = styled(CellValue)`
  color: ${color('contentPrimary')};
`;

export enum InvoiceStatus {
  PENDING = 'pending',
  PENDING_CONFIRMATIONS = 'pending_confirmations',
  PAID_PARTIALLY_WAITING = 'wrong_amount_waiting',
  PAID_PARTIALLY = 'wrong_amount',
  PAID = 'paid',
  OVERPAID = 'paid_over',
  EXPIRED = 'expired',
  ERROR = 'system_error',
}

export interface Currency {
  name: string;
  enum: string;
  icon: string;
}

interface AmountParts {
  exchange_rate: number;
  amount: number;
  network_fee: number;
  platform_fee: number;
}

export interface Network {
  name: string;
  enum: string;
  token_standard: string | null;
  amount: number;

  fiat_amount: number;
  amount_parts: AmountParts;
}

interface PendingTransaction {
  hash: string;
  confirmations: number;
  required_confirmations: number;
  explorer_link: string;
}

export interface Invoice {
  id: string;
  amount: number;
  crypto_amount: number;
  remaining_crypto_amount: number;
  is_payment_multiple: boolean;
  fiat_currency: string;
  status: InvoiceStatus;
  expires_at: number;
  created_at: number;

  amount_parts?: AmountParts; // todo: fix typing

  currency: Currency;
  network: Network;
  wallet: string;
  pending_transaction?: PendingTransaction;

  branding: {
    icon: string|null;
    light_icon: string|null;
    name: string;
    site_url: string;
    fail_url: string;
    success_url: string;
    is_partial_manual_debit_allowed: boolean;
  }
}